import { Button } from 'antd'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import styled from 'styled-components'

import Logo from '../logo/Logo'
import User from './user/user'

const Wrapper = styled.nav`
    height: 100%;
    display: flex;

    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 1em 2em 1em 1em;
`

const StyledNav = styled.nav`
    display: flex;
    flex-wrap: wrap;

    align-items: center;
    justify-content: space-around;
`

const StyledLink = styled.span`
    color: black;
    font-weight: 600;
    &:hover {
        opacity: 50%;
    }
`

type Props = {}

const Navbar: React.FC<Props> = () => {
    const router = useRouter()

    return (
        <Wrapper>
            <Link href="/" passHref>
                <a>
                    <Logo height="3.5em" width="8em" />
                </a>
            </Link>
            <StyledNav>
                <div
                    style={{
                        marginRight: '1em',
                    }}
                >
                    <Button
                        type="primary"
                        style={{
                            background: '#6874E8',
                            borderColor: '#6874E8',
                            fontWeight: 600,
                        }}
                        onClick={() => {
                            router.push('https://discord.gg/JUAwaAKP6D')
                        }}
                    >
                        Join Discord
                    </Button>
                    <Button
                        type="text"
                        onClick={(event) => {
                            event.preventDefault()
                            router.push('/')
                        }}
                    >
                        <StyledLink>Statistics</StyledLink>
                    </Button>
                    <Button
                        type="text"
                        onClick={(event) => {
                            event.preventDefault()
                            router.push('/timelines')
                        }}
                    >
                        <StyledLink>My timelines</StyledLink>
                    </Button>
                </div>
                <User />
            </StyledNav>
        </Wrapper>
    )
}

export default Navbar
