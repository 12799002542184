export const MediaSmallPhone = 370
export const MediaPhone = 490
export const MediaLargePhone = 410
export const MediaTablet = 995
export const MediaDesktop = 996

export const deviceMap = {
    smallPhone: `(max-width: ${MediaSmallPhone}px)`,
    phone: `(max-width: ${MediaPhone}px)`,
    largePhone: `(min-width: ${MediaLargePhone}px) and (max-width: ${MediaPhone}px)`,
    tablet: `(max-width: ${MediaTablet}px)`,
    laptop: `(min-width: ${MediaDesktop}px)`,
}

export enum Device {
    None,
    Phone,
    Tablet,
    Laptop,
}
