import Link from 'next/link'
import React from 'react'
import styled from 'styled-components'

import { deviceMap } from '../../consts/media'
import Logo from '../logo/Logo'

const Wrapper = styled.footer`
    display: flex;
    width: 100%;
    background-color: rgb(241, 243, 247);
    color: rgb(107, 114, 128);

    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 2em 2em;
    gap: 2em;
`

const Trademark = styled.div`
    display: flex;
    width: fit-content;
    height: fit-content;

    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

const Copyright = styled.span`
    text-align: left;
    font-size: 0.75rem;
`

const LinkCardWrapper = styled.div`
    width: fit-content;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 3em;
`

const LinkCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;
`

const Title = styled.span`
    font-size: 1rem;
    color: black;
    font-weight: 600;
`

const Links = styled.div`
    display: flex;
    height: 100%;

    justify-content: center;
    flex-direction: column;

    @media ${deviceMap.laptop}, ${deviceMap.tablet} {
        gap: 0.5em;
        align-items: flex-start;
    }

    @media ${deviceMap.phone} {
        gap: 0.25em;
        align-items: flex-start;
    }
`

const StyledLink = styled.a`
    color: rgb(107, 114, 128);

    &:hover {
        opacity: 75%;
    }
`

type Props = {}

const Footer: React.FC<Props> = () => (
    <Wrapper>
        <Trademark>
            <Link href="/" passHref>
                <a>
                    <Logo height="3.5em" width="10em" />
                </a>
            </Link>
            <Copyright>
                Copyright © 2022. timelinefyi.com <br />
                All rights reserved.{' '}
            </Copyright>
        </Trademark>
        <LinkCardWrapper>
            <LinkCard>
                <Title>Legal</Title>
                <Links>
                    <Link href="https://timelinefyi.com/legal/tos" passHref>
                        <StyledLink>Terms of Service</StyledLink>
                    </Link>
                    <Link href="https://timelinefyi.com/legal/privacy" passHref>
                        <StyledLink>Privacy Policy</StyledLink>
                    </Link>
                    <Link href="https://timelinefyi.com/legal/cookie" passHref>
                        <StyledLink>Cookie Policy</StyledLink>
                    </Link>
                </Links>
            </LinkCard>
            <LinkCard>
                <Title>Company</Title>
                <Links>
                    <Link href="https://timelinefyi.com/about" passHref>
                        <StyledLink>About</StyledLink>
                    </Link>
                    <Link href="https://timelinefyi.com" passHref>
                        <StyledLink>Product</StyledLink>
                    </Link>
                    <Link href="https://timelinefyi.com/blog" passHref>
                        <StyledLink>Blog</StyledLink>
                    </Link>
                    <Link href="https://timelinefyi.com/contact" passHref>
                        <StyledLink>Contact</StyledLink>
                    </Link>
                    <Link href="https://timelinefyi.com/asset" passHref>
                        <StyledLink>Asset</StyledLink>
                    </Link>
                </Links>
            </LinkCard>
            <LinkCard>
                <Title>Contact us</Title>
                <Links>
                    <Link href="https://discord.gg/yR3PjFgYzM" passHref>
                        <StyledLink>Discord</StyledLink>
                    </Link>
                    <Link href="https://timelinefyi.com/contact" passHref>
                        <StyledLink>Airtable</StyledLink>
                    </Link>
                </Links>
            </LinkCard>
        </LinkCardWrapper>
    </Wrapper>
)

export default Footer
