import Image from 'next/image'
import React from 'react'
import styled from 'styled-components'

import LogoImg from '../../public/images/logo.png'

interface WrapperProps {
    height: string
    width: string
}
const Wrapper = styled.div<WrapperProps>`
    display: block;
    position: relative;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
`

type Props = {
    height: string
    width: string
}

const Logo: React.FC<Props> = ({ height, width }) => (
    <Wrapper height={height} width={width}>
        <Image
            src={LogoImg}
            alt="timelinefyi"
            layout="fill"
            objectFit="contain"
            quality={100}
        />
    </Wrapper>
)

export default Logo
