import React from 'react'
import styled from 'styled-components'

import Footer from '../components/footer/Footer'
import Navbar from '../components/navbar/Navbar'

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Content = styled.section`
    display: flex;
    width: 100%;

    min-height: 100vh;
    flex-direction: column;
`

const Container = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
`

type Props = {
    children: React.ReactElement
}

const Layout: React.FC<Props> = ({ children }) => (
    <Wrapper>
        <Content>
            <Navbar />
            <Container>{children}</Container>
        </Content>
        <Footer />
    </Wrapper>
)

export default Layout
