import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import type { User } from '@timelinefyi/types'
import { Avatar } from 'antd'
import React from 'react'
import styled from 'styled-components'

import useAuth from '../../../hooks/useAuth'

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`

const AvatarButton = styled.button`
    border: none;
    background: none;

    cursor: pointer;
`

const DropdownItemLink = styled.span`
    color: black;
`

interface UserAvatarProps {
    user: User
}

const UserAvatar: React.FC<UserAvatarProps> = ({ user }) => {
    const { signout } = useAuth()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const onSignout = async (event: React.MouseEvent) => {
        event.preventDefault()
        signout()
    }

    return (
        <Wrapper>
            <AvatarButton
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    setAnchorEl(event.currentTarget)
                }}
            >
                <Avatar
                    src={user.avatar}
                    style={{
                        color: '#f56a00',
                        backgroundColor: '#fde3cf',
                    }}
                    size={40}
                >
                    {user.nickname?.slice(0, 1) || user.username.slice(0, 1)}
                </Avatar>
            </AvatarButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => window.open(`/users/me`)}>
                    <DropdownItemLink>Your Space</DropdownItemLink>
                </MenuItem>
                <MenuItem onClick={onSignout}>
                    <DropdownItemLink>Logout</DropdownItemLink>
                </MenuItem>
            </Menu>
        </Wrapper>
    )
}

export default UserAvatar
