export const isEmpty = (array: any[] | null) => !array || array.length === 0
export const getLastWithDefault = (array: any[], defaultValue: any) =>
    array.length ? array[array.length - 1] : defaultValue
export const getWithDefault = (
    array: any[],
    index: number,
    defaultValue: any,
) => (array && index < array.length && index >= 0 ? array[index] : defaultValue)
export const getLast = (array: any[], length: number) =>
    array.slice(Math.max(array.length - length, 0))
export const upsert = (array: any[], element: any): any => {
    if (!element) return array
    const i = array.findIndex((_element) => _element.id === element.id)
    if (i > -1) array[i] = element
    else array.push(element)
    return array
}
export const upsertWithCopy = (array: any[], element: any): any[] => {
    const copy = [...array]
    if (!element) return copy
    const i = copy.findIndex((_element) => _element.id === element.id)
    if (i > -1) copy[i] = element
    else copy.push(element)
    return copy
}
