import { Button } from 'antd'
import { useRouter } from 'next/router'
import React from 'react'
import styled from 'styled-components'

import useAuth from '../../../hooks/useAuth'
import UserAvatar from './user.navbar'

const AvatarWrapper = styled.div`
    display: inline-flex;
    height: 3em;
    width: 3em;

    align-items: center;
    justify-content: center;
`

const ButtonWrapper = styled.div`
    display: flex;
    gap: 0.5em;
`

type Props = {}

const User: React.FC<Props> = () => {
    const { user } = useAuth()
    const router = useRouter()

    const content = React.useMemo(() => {
        if (!user) {
            return (
                <ButtonWrapper>
                    <Button type="link" onClick={() => router.push('/login')}>
                        Login
                    </Button>
                    <Button
                        type="primary"
                        style={{
                            background: '#6874E8',
                            borderColor: '#6874E8',
                        }}
                        onClick={() => router.push('/signup')}
                    >
                        Signup
                    </Button>
                </ButtonWrapper>
            )
        }

        return (
            <div>
                <AvatarWrapper>
                    {user && <UserAvatar user={user} />}
                </AvatarWrapper>
            </div>
        )
    }, [router, user])

    return <div>{content}</div>
}

export default User
