import type {
    Category,
    CategoryClient,
    CategoryId,
    ListCategoriesRequest,
} from '@timelinefyi/types'

import client from '../client'

const getById = async (id: CategoryId): Promise<Category> => {
    const response = await client.get(`/public/categories/${id}`)
    return response?.data?.data || null
}

const list = async (
    payload: ListCategoriesRequest,
): Promise<CategoryClient[]> => {
    const response = await client.post(
        `/public/categories/list/client`,
        payload,
    )
    return response?.data?.data || []
}

const create = async (payload: Category): Promise<Category> => {
    const response = await client.post(`/categories`, payload)
    return response?.data?.data || []
}

export const CategoryApiClient = {
    getById,
    create,
    list,
}
