export const parseSortBy = (sortBy: Map<string, number>): string => {
    const result: string[] = []
    // eslint-disable-next-line no-restricted-syntax
    for (const key of sortBy.keys()) {
        result.push(`sort[${key}]=${sortBy.get(key)}`)
    }
    return result.join('&')
}

export const parseMap = (toParse: Map<any, any>): any =>
    Array.from(toParse.entries()).reduce((obj: any, [key, value]) => {
        obj[key] = value
        return obj
    }, {})
